import React, { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import Collapse from "../../components/atoms/colapso";
import Footer from "../../components/organisms/footer";
import NavBar from "../../components/organisms/navbar";
import { FaMapMarkerAlt } from 'react-icons/fa';
//import useAuthenticate from "../../hooks/useAuthenticate";
import {
  Container,
  Content,
  H3,
  H5,
  Location,
  P,
  QRCodeImg,
  Span,
  Title,
  TournamentActions,
  TournamentBanner,
  TournamentContainer,
  TournamentContainerBanner,
  TournamentContent,
  TournamentDescription,
  TournamentEnrollmentLink,
  TournamentEnrollmentLinkEndpage,
  TournamentLink,
  EditalContainer,
  EditalContent,
  EditalTitle
} from "./styles";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api";
import Swal from "sweetalert2";
// import tabelaPeso from "../../assets/images/tournaments/toledo-international-open-brazilian-jiu-jitsu-2023/tabepla-de-peso.jpeg";
import tabelaPeso from "../../assets/images/tournaments/ii-copa-arena-parintins-de-jiu-jitsu/tabela-campeonato-parintinense-de-jiu-jitsu-2025.jpg";
import qrCodecopaAmizadeDeJiuJitsu2024 from "../../assets/images/tournaments/copa-amizade-de-jiu-jitsu-2024/qr-coce--copa-amizade-de-jiu-jitsu-2024.jpg";

//Patrocinio
import torneio from "../../assets/images/sponsors/torneio-facil.png";
import alexGarciaVereador from "../../assets/images/sponsors/alex-garcia-vereador.png";
import navioPrincipeDoAmazonas from "../../assets/images/sponsors/navio-principe-do-amazonas.jpeg";
import netoBrelaz from "../../assets/images/sponsors/neto-brelaz.jpeg";
import parisAutoescola from "../../assets/images/sponsors/paris-auto-escola.jpeg";
import semjuv from "../../assets/images/sponsors/semjuv.jpeg";


//Apoio
import trevusMartialArts from "../../assets/images/sponsors/trevus-martial-arts.jpg";
import { SponsorContainer, SponsorList } from "src/components/atoms/sponsor-area/styles";
import SponsorButton from "src/components/atoms/sponsor-area";
import useAuthenticate from "../../hooks/useAuthenticate";

const sponsors = [
 /*  { img: alexGarciaVereador },
  { img: navioPrincipeDoAmazonas },
  { img: semjuv }, */
  { img: torneio, url: "http://www.torneiofacil.com" },
];


const support = [
  //{ img: esporteNaPeriferia },
  { img: torneio, url: "http://www.torneiofacil.com" },
];


const dataEvento = "Março de 2025 - (dia a definir)"
const horaEvento = "8:30h da manhã"
const tituloEvento = "Campeonato Parintinense de Jiu Jitsu 2025"
const localEvento = "Arena Olímpica Rubens dos Santos"
const endereçoEvento = "Endereço: R. Barreirinha - São Vicente de Paula, Parintins - AM, 69153-005"
const dataDeEncerramentoIncricao = ""
const horaDeEncerramentoIncricao = "23h:59min"

const dataDeEncerramentoCorrecao = "Março-2025 (dia a definir)"
const horaDeEncerramentoCorrecao = "23h:59min"

const dataDeEncerramentoInscricaoLote1 = "22/02/2025"
const dataDeEncerramentoInscricaoLote2 = "Março-2025 (dia a definir)"

const valorinscricaoCategoriaLote1 = "65,00 (sessenta e cinco reais)"
const valorinscricaoCategoriaLote2 = "75,00 (setenta e cinco reais)"

const valorinscricaoAbsolutoLote1 = "65,00 (sessenta e cinco reais)"
const valorinscricaoAbsolutoLote2 = "75,00 (setenta e cinco reais)"

const dataDeDivulgacaoChaves = "Março-2025 (dia a definir)"
const horaDeDivulgacaoChaves = "23h:59min"

const instituicaoBancaria = "Bradesco"
const tipoDeChavePix = "Telefone"
const ChavePix = "(92) 99468-5161"
const BeneficiarioDaChavePix = "José Michel Tavares Pio"

const dataDeDivulgacaoCronograma = " "
const horaDeDivulgacaoCCronograma = " "

const contatoWhatsApp = "(92) 99468-5161"
const contatoWhatsAppTorneio = "+55 (11) 94456-3515"

const slugGI = "campeonato-parintinense-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-gi";
const slugNOGI = "campeonato-parintinense-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-nogi";
const rotaInscriçãoGi = "/enrollment/campeonato-parintinense-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-gi";
const rotaChecagemGi = "/check/campeonato-parintinense-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-gi/search";
const rotaChecagemNoGi = "/check/campeonato-parintinense-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-nogi/search";
const rotaChavesGI = "#http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste"


const IiCopaArenaParintinsDeJiuJitsu: React.FC = () => {

  useEffect(() => {
    // Adiciona cache buster se não estiver presente
    if (!window.location.search.includes("cacheBuster")) {
      const cacheBuster = `cacheBuster=${new Date().getTime()}`;
      const newUrl = `${window.location.pathname}?${cacheBuster}`;
      window.location.replace(newUrl); // Redireciona para a nova URL
    }
  }, []);


  const { authenticated } = useAuthenticate();
  const navigate  = useNavigate();
  const [tournamentGI, setTournamentGI] = useState<any>();
  const [tournamentNOGI, setTournamentNOGI] = useState<any>();

  useEffect(() => {
    api.get(`/tournaments/${slugGI}`).then(({ data }) => {
      setTournamentGI(data);
    });

    api.get(`/tournaments/${slugNOGI}`).then(({ data }) => {
      setTournamentNOGI(data);
    });
  },[]);

  const handleToSubscriptionPage = (url: string) => {
    if(tournamentGI!.canSubscribe || tournamentNOGI!.canSubscribe){
      navigate(url);
      return;
    }

    const msg = "Período de inscrição encerrado.";

    Swal.fire({
      title: 'Atenção!',
      text: msg,
      icon: 'error',
    });

    toast.error(msg);
  }
  return (
    <>
      <NavBar />

      <Container>
        <TournamentContainer
          image={require("../../assets/images/tournaments/ii-copa-arena-parintins-de-jiu-jitsu/ii-copa-arena-parintins-de-jiu-jitsu-2024.jpeg")}
        >
          <TournamentContainerBanner>
            <TournamentBanner
              image={require("../../assets/images/tournaments/ii-copa-arena-parintins-de-jiu-jitsu/ii-copa-arena-parintins-de-jiu-jitsu-2024.jpeg")}
            />
          </TournamentContainerBanner>
        </TournamentContainer>
        <TournamentContent>
          <Content>
            <Title>{tituloEvento}</Title>
            <Location>
              <Span>Data: {dataEvento}</Span>
              <Span>Início: {horaEvento}</Span>
              <Span>Local: {localEvento}</Span>
              <Span>Endereço: {endereçoEvento}</Span>
              <Span>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                  <FaMapMarkerAlt style={{ marginRight: '8px', color: "red" }} />
                  <a
                    target={"blank"}
                    style={{ color: "#000", textDecoration: "none", fontWeight: "bold" }}
                    href="https://maps.app.goo.gl/nG65wMTvE9kjgB9V7"
                  >
                    Ver no Google Maps
                  </a>
                </div>
              </Span>
            </Location>
            <P></P>
            <br />

          </Content>
        </TournamentContent>
        <TournamentDescription>
          <TournamentActions>
            <Content>
              <h1 style={{ textAlign: "center" }}>PATROCINADORES</h1>
              <SponsorContainer>
                <SponsorList>
                  {sponsors && sponsors.map((buttons: any, index: number) => (
                    <SponsorButton
                      key={index} // Adicionei uma chave única para cada botão
                      img={buttons.img}
                      url={buttons.url}
                      hasError={false}
                      error="Este campo é obrigatório"
                      target="_blank" // Adicione este atributo
                    />
                  ))}
                </SponsorList>
              </SponsorContainer>
              <h1 style={{ textAlign: "center" }}>APOIO</h1>
              <SponsorContainer>
                <SponsorList>
                  {support &&
                    support.map((buttons: any) => (
                      <SponsorButton
                        img={buttons.img}
                        url={buttons.url}
                        hasError={false}
                        error="Este campo é obrigatório"
                      />
                    ))}
                </SponsorList>
              </SponsorContainer>
            </Content>

            <Content style={{
              marginTop: '32px'
            }}>

              <EditalContainer>
                <EditalTitle>EDITAL {tituloEvento.toLocaleUpperCase()}</EditalTitle>
                <EditalContent>
                  <Span>
                    O {tituloEvento} marca sua estreia no cenário esportivo   do
                    norte do Brasil fomentando e incentivando a prática do jiu jitsu
                    no estado do Amazonas . As lutas serão GI (com kimono) e NOGI (sem kimono) , o
                    evento disponibilizará categorias para todas as idades e faixas,
                    conforme tabela de peso por categoria deste edital. Os
                    competidores terão a oportunidade de testar suas habilidades em
                    um ambiente controlado e com acompanhamento de profissionais
                    qualificados.
                  </Span>
                  <br />
                  <br />
                  <br />
                </EditalContent>

                <Collapse title="PROTOCOLO DE SEGURANÇA CONTRA COVID-19">
                  <P>
                    Devido a dinâmica da situação atual, estamos avaliando diariamente as
                    recomendações dos Órgãos de Saúde e este protocolo pode ser atualizado a
                    qualquer momento caso novas determinações sejam publicadas.
                  </P>
                  <P>
                    Caso o evento seja cancelado por força maior, todas as inscrições serão
                    automaticamente reembolsadas.
                  </P>
                  <P>
                    A organização não é responsável pelos demais custos dos participantes,
                    tais como transporte e hospedagem.
                  </P>
                  <P>
                    Diretrizes do ginásio: Para a segurança de todos, por favor verifique
                    sua temperatura antes de se dirigir ao evento.{" "}
                  </P>
                  <P>Caso haja qualquer sintoma, procure um médico.</P>{" "}
                  <P>
                    {" "}
                    Caso você se sinta indisposto ou esteve em contato com um paciente
                    diagnosticado com Covid-19, por favor fique em casa e procure seu
                    médico.
                  </P>
                  {/* <P>
                  Todo nosso material será desinfetado antes e durante o evento.
                </P> */}
                </Collapse>
                <Collapse title="PREMIAÇÕES DINHEIRO💰, MEDALHAS🥇">
                  <H3>Por Equipes </H3>
                  <P>
                    1° lugar: 💰R$ 2500 + 🏆troféu
                  </P>
                  <P>
                    2° lugar: 💰R$ 1000 + 🏆troféu
                  </P>
                  <P>
                    3° lugar: 💰R$ 500 + 🏆troféu
                  </P>
                  <br />
                  <br />
                  <H3>Absoluto Masculino GI:</H3>
                  
                  <H5>Adulto Azul e Roxa (juntos)</H5>
                  <P>1° lugar: R$250,00 + medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Master Azul e Roxa (juntos)</H5>
                  <P>1° lugar: R$250,00 + medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Adulto  Marrom  e Preta (juntos)</H5>
                  <P>1° lugar: R$1.000,00 + cinturão🥇</P>
                  <P>2° lugar: R$300,00 + medalha🥈</P>
                  <P>3° lugar: R$200,00 + medalha🥉</P>
                  <H5>Masters Marrom  e Preta (juntos)</H5>
                  <P>1° lugar: R$600,00🥇</P>
                  <P>2° lugar: R$250,00 + medalha🥈</P>
                  <P>3° lugar: R$100,00 + medalha🥉</P>
                  <br />
                  <H3>Absoluto Feminino GI:</H3>
                 
                  <H5>Adulto Azul e Roxa (juntos)</H5>
                  <P>1° lugar: R$250,00 + medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Master Azul e Roxa (juntos)</H5>
                  <P>1° lugar: R$250,00 + medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Adulto Marrom  e Preta (juntos)</H5>
                  <P>1° lugar: R$1.000,00 + cinturão🥇</P>
                  <P>2° lugar: R$300,00 + medalha🥈</P>
                  <P>3° lugar: R$200,00 + medalha🥉</P>

                  <H5>Master Marrom  e Preta (juntos)</H5>
                  <P>1° lugar: R$600,00🥇</P>
                  <P>2° lugar: R$250,00 + medalha🥈</P>
                  <P>3° lugar: R$100,00 + medalha🥉</P>
                  <br />

                  <H3>Absoluto Masculino NOGI:</H3>
                  <H5>Adulto e Master - Marrom  e Preta (juntos)</H5>
                  <P>1° lugar: R$500,00 + medalha🥇</P>
                  <P>2° lugar:  medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
             
                  <br />
                  <H3>Absoluto Feminino NOGI:</H3>
                 
                  <H5>Adulto e Master - Marrom  e Preta (juntos)</H5>
                  <P>1° lugar: R$500 + medalha🥇</P>
                  <P>2° lugar:  medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  
                  <br />
                  <H3>Demais Categorias</H3>
                  <P>1° lugar: medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <br />
                  <p>OBS: Estas premiações só irão valer para os Absolutos GI (COM KIMONO) que tiverem 08 (OITO)
                    ou mais atletas inscritos.</p>
                  <p>Em caso com menos de 8 (OITO) inscritos no Absoluto GI, não haverá a premiação em dinheiro para campeão, para segundo
                    e terceiro permanece a premição normal.
                  </p>
                  <p>Estas premiações só irão valer para os Absolutos NOGI (SEM KIMONO) que tiverem 06 (SEIS)
                    ou mais atletas inscritos.</p>
                  <p>Em caso com menos de 6 (SEIS) inscritos no Absoluto NOGI, não haverá a premiação em dinheiro para campeão, para segundo
                    e terceiro permanece a premição normal.
                  </p>
                  <p>Mais informações sobre as inscriçoes para o absoluto esta disponível na seção
                    INSCRIÇÕES, paragrafo Inscrição de Absoluto deste edital.
                  </p>
                  <br />
                </Collapse>

                <Collapse title="INSCRIÇÕES">
                  <Span>
                    As inscrições para o evento serão realizadas exclusivamente online, por
                    meio do site www.torneiofacil.com.br
                  </Span>
                  <P>
                    Durante o processo de inscrição, o atleta ou seu responsável legal
                    deverá selecionar corretamente a categoria referente idade que o atleta
                    completará no ano de 2025, bem como sua faixa e peso.
                  </P>
                  <P>
                    A idade de competição do atleta é a idade máxima que ele terá em 2025,
                    conforme regra da CBJJ.
                  </P>
                  <br />
                  <H3>Prazo de Inscrição</H3>
                  <P>
                    O prazo final para inscrições e pagamento será até  {dataDeEncerramentoIncricao}, às
                    {horaDeEncerramentoIncricao}min.
                  </P>
                  <br />
                  <P>As inscrições que não forem pagas serão canceladas.</P>
                  <br />
                  <H3>Correção</H3>
                  <P>
                    A correção da inscrição poderá ser realizada somente após a confirmação
                    do pagamento.
                  </P>
                  <P>
                    Em caso de inscrição equivocada, o atleta ou professor responsável
                    poderá corrigir as informações no site www.torneiofacil.com.br,
                    acessando a opção Meu Perfil em seguida Minhas Inscrições.
                  </P>
                  <P>
                    A correção da inscrição ficará disponivel até o encerramento das
                    inscrições {dataDeEncerramentoCorrecao} as {horaDeEncerramentoCorrecao}.
                  </P>
                  <P>
                    Após prazo para correção das inscrições, não serão aceitas modificações
                    nas inscrições.
                  </P>
                  <P>
                    Os atletas que se inscreverem incorretamente estarão sujeitos a
                    desclassificação <b>sem direito a reembolso.</b>
                  </P>
                  <br />
                  <H3>Prazo de Correção</H3>
                  <P>
                    A correção da inscrição fica disponível após a confirmação do pagamento
                    da mesma.
                  </P>
                  <P>
                    A correção da inscrição ficará disponivel até o encerramento das
                    inscrições {dataDeEncerramentoCorrecao} as {horaDeEncerramentoCorrecao}.
                  </P>
                  <H3>Checagem</H3>
                  <P>
                    O sorteio e divulgação das chaves de lutas será em {dataDeDivulgacaoChaves} até as {horaDeDivulgacaoChaves}.
                  </P>
                  <br />
                  <H3>Inscrição de Absoluto</H3>
                  <P>
                    Somente atletas inscritos na categoria poderão se increver e participar
                    do absoluto.
                  </P>
                  <P>
                    Os atletas só poderão se inscrever no absoluto referente a categoria da
                    sua inscrição no torneio. Exceção apenas para os master que poderá se
                    inscrever como absoluto adulto.
                  </P>
                </Collapse>
                <Collapse title="PESSOAS COM DEFICIÊNCIA (PCD)">
                  <H3>
                    As pessoas com deficiência (PCD) devem informar, no momento da
                    inscrição, sua condição pelo WhatsApp. Caso contrario o atleta será
                    desclassificado sem direito a reembolso.
                  </H3>
                  <P>
                    Haverá um horário reservado de 11:00h às 11:30 para todos os atletas PCD .
                  </P>
                  <br />
                </Collapse>

                <Collapse title="VALOR DA INSCRIÇÃO">
                  <Span>
                    As inscrições serão divididas em dois lotes
                  </Span>
                  <br />
                  <H3>Primeiro Lote - Pagamento até {dataDeEncerramentoInscricaoLote1}</H3>
                  <P>Inscrição GI categoria R$ {valorinscricaoCategoriaLote1}</P>
                  <P>Inscrição GI absoluto R$ {valorinscricaoAbsolutoLote1}</P>
                  <P>Inscrição NOGI categoria R$ {valorinscricaoCategoriaLote1}</P>
                  <P>Inscrição NOGI absoluto R$ {valorinscricaoAbsolutoLote1}</P>
                  <br />
                  <H3>Segundo Lote - Pagamento até {dataDeEncerramentoInscricaoLote2}</H3>
                  <P>Inscrição GI categoria R$ {valorinscricaoCategoriaLote2}</P>
                  <P>Inscrição GI absoluto R$ {valorinscricaoAbsolutoLote2}</P>
                  <P>Inscrição NOGI categoria R$ {valorinscricaoCategoriaLote2}</P>
                  <P>Inscrição NOGI absoluto R$ {valorinscricaoAbsolutoLote2}</P>
                  <br />
                  <br />
                </Collapse>
                <Collapse title="PAGAMENTO">
                  <H3>
                    A confirmação de pagamento deverá ser realizada via WhatsApp enviando o
                    comprovante para o contato abaixo:
                  </H3>
                  <P>Banco: {instituicaoBancaria}</P>
                  <P>Titular: {BeneficiarioDaChavePix}</P>
                  <P>Chave PIX: {tipoDeChavePix}</P>
                  <P>Chave PIX: {ChavePix}</P>
                  {/* <QRCodeImg src={qrCodecopaAmizadeDeJiuJitsu2024} alt="qrCode" width={300} />
                  <P>
                    <a
                      target={"blank"}
                      style={{ color: "white" }}
                      href="https://whatsa.me/5592981291027/?t=Ol%C3%A1+Thiago_Dellarmelina%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+na+%2ACOPA+ORLEY+LOBATO+DE+JIU+JITSU+2024%2A"
                    >
                      <strong>WhatsApp: +55 (92) 98129-1027 </strong>
                      <FaWhatsapp />
                    </a>
                  </P> */}
                  <p />
                  {/* <QRCodeImg src={qrCodeCopaParana} alt="qrCode" width={300} /> */}
                </Collapse>

                <Collapse title="REGRAS">
                  <Span>
                    Todas as regras para o evento estão devidamente estipuladas e detalhadas neste edital.
                  </Span>
                  <Span>
                    Em casos de omissão de regras no presente edital, as normas a serem observadas serão aquelas estabelecidas pela Confederação
                    Brasileira de Jiu-Jitsu (CBJJ), em conformidade com as diretrizes da CBJJ/IBJJF.
                  </Span>
                  <Span>
                    A tabela de peso por categoria, que estabelece os limites de peso para cada categoria, encontra-se disponível no site oficial do evento,
                    acessível por meio do botão denominado "Tabela de Peso".
                  </Span>
                  <Span>
                    As lutas serão com GI (com kimonos de cores Oficiais pela cbjj branco,
                    azul e preto ) os kimonos devem estar limpos sem rasgos e sem odor .
                  </Span>
                  <Span>
                    Para categoria com 3 lutadores, o lutador que ficar sozinho no sorteio da chave estará automaticamente na final.
                    Em caso de WO de um atleta, os dois lutadores restantes na categoria disputarão a final.
                  </Span>
                  <Span>
                    A pesagem será realizada no dia do evento. O competidor deverá
                    apresentar documento oficial com foto no momento da pesagem.
                  </Span>
                  <Span>
                    A pesagem dos atletas serão realizadas antes do inicio da categoria.
                  </Span>
                  <Span>
                    Atletas que ultrapasarem o limite de peso da categoria que entiverem
                    inscritos serão automaticamente desclassificados e não poderão lutar o
                    Absoluto.
                  </Span>
                  <Span>Será realizada apenas uma pesagem oficial por atleta.</Span>
                  <Span>
                    Teremos uma balança para pesagem livre (não oficial) para os atletas.
                  </Span>
                  <Span>Teremos duas balanças para pesagem oficial dos atletas.</Span>
                  <Span>Teremos 4 (quatro) aréas de lutas</Span>
                  <Span>A Arbitragem será composta pela Equipe da Federação de Arbítrio do Amazonas</Span>
                  <Span>
                    Atletas que lutaram em outros eventos com faixa superior não poderão
                    lutar com a faixa abaixo. Se descoberto, o competidor será eliminado do
                    torneio sem direito a reembolso.
                  </Span>
                  <br />
                  <H3>Pontuação por equipe</H3>
                  <H5>Categoria e Absoluto, todas as faixas</H5>
                  <P>1° lugar: 9 pontos</P>
                  <P>2° lugar: 3 pontos</P>
                  <P>3° lugar: 1 ponto</P>

                  <P>
                    A organização do evento irá confeccionar um crachá para disponibilizar a apenas um representante por equipe.
                  </P>
                  <P>
                    O representante que for escolhido a usar o crachá fará todas as funções necessárias sobre sua equipe com  relação ao evento.
                  </P>
                  <P>
                    Só ficará 01 representante de cada equipe para
                    tirar todas as dúvidas e os questionamentos só serão aceito vindo do mesmo.
                  </P>
                  <P>
                    O mesmo representante de cada academia finalista subirá no pódio,
                    podendo ter em suas mão a somente a bandeira da academia que foi inscrita no evento. 
                  </P>
                  <P>
                    Crianças até 11 anos, poderão ser acompanhadas em sua área de luta apenas com o representante de sua equipe.
                  </P>
                  <P>
                    A contagem dos pontos será realizada de forma geral contabilizando os
                    pontos de todas as faixas.
                  </P>
                  <P>Campeão direto não contam pontos, chaves com apenas um inscrito.</P>
                  <P>
                    Campeão por WO, devido a desistencia do oponente, contará pontos desde
                    que na chave tenha mais de um inscrito.
                  </P>
                  <P>
                    Categorias com apenas dois atletas da mesma academia não contam pontos
                    para a disputa por academias.
                  </P>
                  <br />
                  <H3>Para o absoluto:</H3>
                  <P>
                     Não será permitido luta combinada.
                  </P>
                  <P>
                     Não será permitido fechamento dos finalista.
                  </P>
                  <P>
                     Não será permitido o atleta deixar de fazer o combate, afim de beneficiar o outro atleta. 
                  </P>
                  <P>
                     Caso aconteça alguns desses itens acima ,  a organização fica sem a obrigação de fazer a entrega  da premiação  citado nesse edital.
                  </P>
                  <br />
                  <P>
                    O que não estiver neste edital, será acatado o que estiver no livro de
                    regras da CBJJ.
                  </P>
                  <br />
                  <P>
                    <a
                      target={"blank"}
                      href="https://cbjj.com.br/books-videos"
                      style={{ color: "white" }}
                    >
                      <strong>LIVRO DE REGRAS DA CBJJ/IBJJF</strong>
                    </a>
                  </P>
                </Collapse>
                <Collapse title={"CRONOGRAMA" + dataDeDivulgacaoCronograma + " " + horaDeDivulgacaoCCronograma}>
                   (EM BREVE) 
                  <Container>


{/* 
                  <H3>TODAS AS LUTAS (GI E NOGI) SERÃO REALIZADAS NO DIA 31/08/2024.</H3>
                    <br />
                    <Span>Início: 08:30 horas</Span>

                    <br />
                    <H5>09:00 horas - PRÉ MIRIM e MIRIM - 04/05 e 06/07 anos (Branca e Graduado) masculino e feminino.</H5>
                    <H5>09:30 horas - INFANTIL A 08/09 anos (Branca e Graduado) masculino e feminino.</H5>
                    <H5>10:00 horas - INFANTIL B-10/11 anos (Branca e Graduado) masculino e feminino.</H5>
                    <H5>10:30 horas - INFANTO-JUVENIL A 12/13 anos (Branca e Graduado) masculino e feminino.</H5>
                    <H5>11:00 horas - INFANTO-JUVENIL B - 14/15 anos (Branca e Graduado) masculino e feminino.</H5>
                    <H5>12:00 horas - JUVENIL 16/17 anos (Branca e Graduado) masculino e feminino.</H5>
                    <H5>13:00 horas - Faixa PRETA (Adulto/Máster/Sênior) masculino e feminino.</H5>
                    <H5>14:00 horas - Faixa Marrom (Adulto/Máster/Sênior) masculino e feminino.</H5>
                    <H5>15:00 horas - Faixa Roxa (Adulto/Máster/Sênior) masculino e feminino</H5>
                    <H5>16:00 horas - Faixa Azul (Adulto/Máster/Sênior) masculino e feminino.</H5>
                    <H5>17:00 horas - Faixa Branca (Adulto/Máster/Sênior) masculino e feminino.</H5> */}
                    {/* <b><Span>Obs: O cronograma definitivo será atualizado no dia 11/11/2023.</Span></b>
                  <br />
                  <Span>Data: 14 de abril de 2024</Span>
                  <Span>Início: 9:00h da manhã</Span>
                  <Span>Local: Ginásio de Esportes Amário Vieira da Costa, Umuarama PR</Span>
                  <Span>Endereço: Av. Gov. Parigot de Souza, 4284-4454 - Zona I, Umuarama - PR, CEP: 87503-680</Span>
                  <br />
                  <H5>9:30h - Abertura do evento</H5>
                  <br />
                  <H3>Lutas GI</H3>
                  <H5>9:45h - 04, 05, 06, 07, 08, 09, 10 e 11 anos (Faixa branca e Graduado) masculino e feminino.</H5>
                  <H5>10:45h - 12, 13, 14 e 15 anos (Faixa branca e Graduado) masculino e feminino.</H5>
                  <H5>11:30h - 16 e 17 anos (Faixa Branca) masculino e feminino.</H5>
                  <H5>11:30h - 16 e 17 anos (Faixa Branca) masculino e feminino.</H5>
                  <H5>12:00h - Adulto (Faixa Branca) masculino e feminino.</H5>
                  <H5>12:40h - Master (Branca) masculino e feminino.</H5>
                  <H5>12:40h - Adulto (Faixa Azul) masculino e feminino.</H5>
                  <H5>13:40h - Adulto (Faixa Roxa) masculino e feminino.</H5>
                  <H5>13:40h - Master (Faixa Azul) masculino e feminino.</H5>
                  <H5>15:00 - Adulto e Master (Faixa Preta ) Masculino e Feminino</H5>
                  <br /> */}
                  {/*   <H5>
                      Todos os atletas devem estar 01(uma) hora antes do tempo de sua
                      categoria na área de aquecimento podendo lutar até 30 min antes do
                      horário previsto no cronograma
                    </H5>
                    <br />
                    <H5>
                      Obs.1: O atleta deverá comparecer no ginasio com uma (01) hora de antecedência do
                      horário previsto no cronograma.
                    </H5> */}

                    {/* <Span>Usaremos 3 áreas de luta</Span> */}
                   {/*  <Span>
                      O cronograma pode ser adiantado em até 1 hora, e atrasar conforme a
                      necessidade do evento.
                    </Span>
                    <Span>
                      O atleta que não estiver presente no momento da chamada para a pesagem
                      ou luta ser desclassificado por W.O.
                    </Span> */}
                  </Container>
                </Collapse>
                <Collapse title="ENTRADA">
                  <P>Será cobrado 1 kg (um quilograma) de alimento não perecível. Para crianças abaixo de 10 (dez) anos 
                    a entrada é liberada. </P>

                  <br />
                  {/* <P>
                  Será cobrado 1kg de alimento não perecível na entrada para atletas e
                  público.
                </P> */}
                  {/* <br />
                <P>Crianças até 5 anos ficam isentas de qualquer tipo de pagamento.</P>
                <br /> */}
                </Collapse>
                <Collapse title="CONTATO">
                <P>
                    Dúvidas sobre cadastro, inscrição, pagamento, correção, checagem ou
                    sugestão de melhorias entre em contato com a equipe do Torneio Fácil
                    por telefone ou WhatsApp.
                  </P>
                  <P>Melhor horário para atendimento das 18:00h as 23:59h.</P>
                  <a
                    target={"blank"}
                    href="https://whatsa.me/5511944563515/?t=Olá%20Equipe%20Torneio%20Fácil!%0A%0APreciso%20de%20ajuda%20com%20minha%20inscri%C3%A7%C3%A3o%20no%20*1º%20CAMPEONATO%20PARINTINENSE%20DE%20JIU%20JITSU*"
                  >
                    <strong>Telefone/WhatsApp: {contatoWhatsAppTorneio} </strong>
                    <FaWhatsapp />
                  </a>

                  <br />
                </Collapse>
              </EditalContainer>
            </Content>
            <br />
            <Content>
              {authenticated ? (
                <>
                  {/* <TournamentEnrollmentLinkEndpage to="/enrollment/ii-copa-arena-parintins-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-gi">
                    Inscrição GI
                  </TournamentEnrollmentLinkEndpage>
                  <TournamentEnrollmentLinkEndpage to="/enrollment/ii-copa-arena-parintins-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-nogi">
                    Inscrição NOGI
                  </TournamentEnrollmentLinkEndpage> */}

                 <TournamentEnrollmentLinkEndpage to={""} onClick={() => handleToSubscriptionPage(rotaInscriçãoGi)}>
                    Inscrição
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/profile/subscription">
                    Correção da Inscrição
                  </TournamentEnrollmentLinkEndpage>
                </>
              ) : (
                <>
                   <TournamentEnrollmentLinkEndpage to="" onClick={() => handleToSubscriptionPage("/login")}>
                    Inscrição
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/login">
                    Correção da Inscrição
                  </TournamentEnrollmentLinkEndpage>
                </>
              )}
              {/* <TournamentLink href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.767B171E&u=teste" target="_blank">Chaves</TournamentLink> */}
              {/* // /check/16-COPA-PARANA-DE-JIU-JITSU */}

              <TournamentLink
                // target="_blank"
                href={rotaChecagemGi}
              >
                Checagem GI
              </TournamentLink>

              <TournamentLink
                // target="_blank"
                href={rotaChecagemNoGi}
              >
                Checagem NOGI
              </TournamentLink>

              {/* 
              <TournamentLink
                href="/check/toledo-international-open-brazilian-jiu-jitsu-nogi-2023-ginasio-de-esportes-alcides-pan-toledo-pr/search"
                target="_blank"
              >
                Checagem de atletas NO GI
              </TournamentLink>  */}

              <TournamentLink
                // target="_blank"
                href={rotaChavesGI}
              >
                Chaves GI e NOGI
              </TournamentLink>

              {/*               <TournamentLink
                href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.5B5B1C9A&u=teste"
                target="_blank"
              >
                Chaves NO GI
              </TournamentLink> */}

              <TournamentLink target="blank" href={tabelaPeso}>
                Tabela de pesos
              </TournamentLink>
              {/* <TournamentLink href="#">Resultados (EM BREVE)</TournamentLink> */}
            </Content>
          </TournamentActions>
        </TournamentDescription>
      </Container>
      <Footer />
    </>
  );
};

export default IiCopaArenaParintinsDeJiuJitsu;
