import { useParams } from "react-router-dom";
import _1copaArenaParintinsDeJiuJitsu from "../../data/rules-1-copa-arena-parintins-de- jiu-jitsu.json";
import EnrollmentUpdateSeletivaManausOlimpica2024 from "./tournament/enrollment-update-seletiva-manaus-olimpica-2024";
import EnrollmentUpdateViiiReiERainhaDaPraiaBeachWrestiling2024 from "./tournament/enrollment-update-viii-rei-e-rainha-da-praia-beach-wrestiling-manaus-amazonas-2024";
import EnrollmentUpdateCopaAmizadeDeJiuJitsu2024 from "./tournament/enrollment-update-copa-amizade-de-jiu-jitsu-2024";
import EnrollmentUpdateCopaMestreAcaiDeJiuJitsu2024 from "./tournament/enrollment-update-copa-mestre-acai-de-jiu-jitsu-2024";
import EnrollmentUpdateXxxiiCopaOrleyLobatoDeJiuJitsu from "./tournament/enrollment-update-xxxii-copa-orley-lobato-de-jiu-jitsu";
import EnrollmentUpdateIvOpenTerrSantaDeJiuJitsu from "./tournament/enrollment-update-iv-open-terra-santa-de-jiu-jitsu";

import EnrollmentUpdateXviiCopaParanaDeJiuJitsu from "./tournament/enrollment-update-xvii-copa-parana-de-jiu-jitsu";
import EnrollmentUpdateIOrixicombatOpenJiuJitsu from "./tournament/enrollment-update-i-orixicombat-open-jiu-jitsu";
import EnrollmentUpdateIiCopaArenaParintinsDejiujitsu from "./tournament/enrollment-update-ii-copa-arena-parintins-de-jiu-jitsu";
import EnrollmentUpdateICopaThomasiDeJiuJitsu from "./tournament/enrollment-update-copa-thomasi-de-jiu-jitsu";
import EnrollmentUpdateCopaLoboBravoCresolDeJiuJitsu from "./tournament/enrollment-update-copa-lobo-bravo-cresol-de-jiu-jitsu";
import EnrollmentUpdateViiOpenAmazonasTopTeamDeJiuJitsu from "./tournament/enrollment-update-vii-open-amazonas-top-team-de-jiu-jitsu";
import EnrollmentUpdateCopaCianorteDeJiuJitsu from "./tournament/enrollment-update-copa-cianorte-de-jiu-jitsu";
import EnrollmentUpdateViReiDaPraiaDeJiuJitsuNoGi from "./tournament/enrollment-update-vi-rei-da-praia-de-jiu-jitsu-no-gi";
import EnrollmentUpdateIiCopaChampiosKidsDeJiuJitsu from "./tournament/enrollment-update-ii-copa-champions-kids-de-jiu-jitsu";
import EnrollmentUpdateIiiOpenRochaEMoraesDeJiuJitsu from "./tournament/enrollment-update-iii-open-rocha-e-moraes-de-jiu-jitsu";
import EnrollmentUpdateIOpenLaranjeirasDoSulDeJiuJitsu from "./tournament/enrollment-update-i-open-laranjeiras-do sul-de-jiu-jitsu";
import EnrollmentUpdateEstadualGfTeamAmazonasDeJiuJitsu from "./tournament/enrollment-update-estadual-gf-team-amazonas-jiu-jitsu";
import EnrollmentUpdateXxixCopaLuisNetoDeJiuJitsu from "./tournament/enrollment-update-xxix-copa-luis-neto-jiu-jitsu";
import EnrollmentUpdateEtapaOriximinaXxxiiCopaOrleyDeJiuJitsu from "./tournament/enrollment-update-etapa-oriximina-xxxii-copa-orley-lobato-jiu-jitsu";
import EnrollmentUpdateXviiiCopaParanaDeJiuJitsu from "./tournament/enrollment-update-xviii-copa-parana-de-jiu-jitsu";
import EnrollmentUpdateCampeonatoParintinenseDeJiuJitsu from "./tournament/enrollment-update-campeonato-parintinense-de-jiu-jitsu";

const EnrollmentUpdate = () => {
  const { slug } = useParams();
  console.log(slug);

  switch (slug) {
    case "seletiva-manaus-olimpica-2024-ginasio-ninimberg-guerra-sao-jorge-manaus-am":
      return <EnrollmentUpdateSeletivaManausOlimpica2024 />;
    case "viii-rei-e-rainha-da-praia-beach-wrestling-2024-nossa-praia-sports":
    case "i-rei-e-rainha-da-praia-beach-boxing-manaus-amazonas-2023-manaus-amazonas":
      return <EnrollmentUpdateViiiReiERainhaDaPraiaBeachWrestiling2024 />;
    case "copa-amizade-de-jiu-jitsu-2024-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr":
    case "copa-fronteira-de-jiu-jitsu-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr":
      return <EnrollmentUpdateCopaAmizadeDeJiuJitsu2024 />;
    case "copa-mestre-acai-de-jiu-jitsu-2024-ginasio-arrudao-francisco-beltao-pr":
      return <EnrollmentUpdateCopaMestreAcaiDeJiuJitsu2024 />;
    case "xxxii-copa-orley-lobato-de-jiu-jitsu-cel-nininberg-guerra-manaus-am":
      return <EnrollmentUpdateXxxiiCopaOrleyLobatoDeJiuJitsu />;
    case "iv-opem-terra-santa-de-jiu-jistu-ginasio-braulio-nelson-da-conceicao-gi":
    case "iv-opem-terra-santa-de-jiu-jistu-ginasio-braulio-nelson-da-conceicao-nogi":
      return <EnrollmentUpdateIvOpenTerrSantaDeJiuJitsu />;
    case "xvii-copa-parana-de-jiu-jitsu-ginasio-de-esportes-alcides-pan-toledo-pr-gi":
    case "xvii-copa-parana-de-jiu-jitsu-ginasio-de-esportes-alcides-pan-toledo-pr-nogi":
      return <EnrollmentUpdateXviiCopaParanaDeJiuJitsu />;
    case "i-orixicombat-open-jiu-jitsu-ginasio-municipal-de-oriximina-pa":
      return <EnrollmentUpdateIOrixicombatOpenJiuJitsu />;
    case "ii-copa-arena-parintins-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-gi":
    case "ii-copa-arena-parintins-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-nogi":
      return <EnrollmentUpdateIiCopaArenaParintinsDejiujitsu />;
    case "i-copa-thomasi-de-jiu-jitsu-parque-do-japao-maringa-pr":
      return <EnrollmentUpdateICopaThomasiDeJiuJitsu />;
    case "copa-lobo-bravo-cresol-de-jiu-jitsu-ginasio-assuncao-de-nossa-senhora-guarapuava-pr":
      return <EnrollmentUpdateCopaLoboBravoCresolDeJiuJitsu />;
    case "vii-open-amazonas-top-team-de-jiu-jitsu-manaus-am-gi":
      return <EnrollmentUpdateViiOpenAmazonasTopTeamDeJiuJitsu />;
    case "copa-cianorte-de-jiu-jitsu-cianorte-pr-gi":
      return <EnrollmentUpdateCopaCianorteDeJiuJitsu />;
    case "vi-rei-da-praia-de-jiu-jitsu-santarem-pa-nogi":
      return <EnrollmentUpdateViReiDaPraiaDeJiuJitsuNoGi />;
    case "ii-copa-champions-kids-de-jiu-jitsu-maringa-pr-gi":
      return <EnrollmentUpdateIiCopaChampiosKidsDeJiuJitsu />;
    case "iii-open-rocha-e-moraes-de-jiu-jitsu-manaus-am-gi":
      return <EnrollmentUpdateIiiOpenRochaEMoraesDeJiuJitsu />;
    case "i-open-laranjeiras-do-sul-de-jiu-jitsu-laranjeiras-pr-gi":
      return <EnrollmentUpdateIOpenLaranjeirasDoSulDeJiuJitsu />;
    case "estadual-gf-team-amazonas-jiu-jitsu-manaus-am-gi":
    case "estadual-gf-team-amazonas-jiu-jitsu-manaus-am-nogi":
      return <EnrollmentUpdateEstadualGfTeamAmazonasDeJiuJitsu />;
    case "xxix-copa-luis-neto-jiu-jitsu-manaus-am-gi":
    case "xxix-copa-luis-neto-jiu-jitsu-manaus-am-nogi":
    case "copa-manacapuru-de-jiu-jitsu-mananacapuru-am-gi":
    case "copa-manacapuru-de-jiu-jitsu-mananacapuru-am-nogi":
      return <EnrollmentUpdateXxixCopaLuisNetoDeJiuJitsu />;
    case "etapa-oriximina-xxxii-copa-orley-lobato-jiu-jitsu-oriximina-pa-gi":
    case "etapa-oriximina-xxxii-copa-orley-lobato-jiu-jitsu-oriximina-pa-nogi":
      return <EnrollmentUpdateEtapaOriximinaXxxiiCopaOrleyDeJiuJitsu />;
    case "campeonato-parintinense-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-gi":
    case "campeonato-parintinense-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-nogi":
      return <EnrollmentUpdateCampeonatoParintinenseDeJiuJitsu />;
    case "xviii-copa-parana-de-jiu-jitsu-cascavel-pr-gi":
    case "xviii-copa-parana-de-jiu-jitsu-cascavel-pr-nogi":
      return <EnrollmentUpdateXviiiCopaParanaDeJiuJitsu />;
    default:
      return <h1>Inscrição para esse Torneio não encontrado!</h1>;
  }
};
export default EnrollmentUpdate;
