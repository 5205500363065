import { useParams } from "react-router-dom";
import AllCheckSeletivaManausOlimpica2024 from "./tournament/check-seletiva-manaus-olimpica-2024";
import AllCheckViiiReiERainhaDaPraiaBeachWrestilingManausAmazonas2024 from "./tournament/check-viii-rei-e-rainha-da-praia-beach-wrestiling-manaus-amazonas-2024";
import AllCheckCopaAmizadeDeJiuJitsu2024 from "./tournament/check-copa-amizade-de-jiu-jitsu-2024";
import AllCheckCopaMestreAcaiDeJiuJitsu2024 from "./tournament/check-copa-mestre-acai-de-jiu-jitsu-2024y";
import AllCheckXxxiiCopaOrleyLobatoDeJiuJitsu from "./tournament/check-xxxii-copa-orley-lobato-de-jiu-jitsu";
import AllCheckIOrixicombatOpenJiuJitsu from "./tournament/check-i-orixicombat-open-jiu-jitsu";
import AllCheckJiuJitsuLaranjaEVerdeJuntos from "./tournament/check-jiu-jitsu-laranja-e-verde-juntos";
import AllCheckCopaLoboBravoCresolDeJiuJitsu from "./tournament/check-copa-lobo-bravo-cresol-de-jiu-jitsu";
import AllCheckIvOpenTerraSantaDeJiuJitsu from "./tournament/check-iv-open-terra-santa-de-jiu-jitsu";
import AllCheckViiOpenAmazonasTopTeamDeJiuJitsu from "./tournament/check-vii-open-amazonas-top-team-de-jiu-jitsu";
import AllCheckCategoriesCopaCianorteDeJiuJitsu from "./tournament/check-copa-cianorte-de-jiu-jitsu";
import AllCheckViReiDaPraiaDeJiuJitsuNoGi from "./tournament/check-vi-rei-da-praia-de-jiu-jitsu-no-gi";
import AllCheckIiCopaChampionsKidsDeJiuJitsu from "./tournament/check-ii-copa-champions-kids-de-jiu-jitsu";
import AllCheckIiiOpenRochaEMoraesDeJiuJitsu from "./tournament/check-iii-open-rocha-e-moraes-de-jiu-jitsu";
import AllCheckIOpenLaranjeirasDoSulDeJiuJitsu from "./tournament/check-i-open-laranjeira-do-sul-de-jiu-jitsu";
import AllCheckEstadualGfTeamAmazonasDeJiuJitsu from "./tournament/check-estadual-gf-team-amazonas-jiu-jitsu";
import AllCheckXxixCopaLuisNetoDeJiuJitsu from "./tournament/check-xxix-copa-luis-neto-jiu-jitsu";
import AllCheckEtapaOriximinaXxxiiCopaOrleyLobatoDeJiuJitsu from "./tournament/check-etapa-oriximina-xxxii-copa-orley-lobato-jiu-jitsu";
import AllCheckCampeonatoParintinsenseDeJiuJitsu from "./tournament/check-campeonato-parintinense-de-jiu-jitsu";
import AllCheckXviiiCopaParanaDeJiuJitsu from "./tournament/check-xviii-copa-parana-de-jiu-jitsu";

function AllCheck() {
  const { slug } = useParams();
  switch (slug) {
    case "seletiva-manaus-olimpica-2024-ginasio-ninimberg-guerra-sao-jorge-manaus-am":
    case "i-rei-e-rainha-da-praia-beach-boxing-manaus-amazonas-2023-manaus-amazonas":
      return <AllCheckSeletivaManausOlimpica2024 />;
    case "viii-rei-e-rainha-da-praia-beach-wrestling-2024-nossa-praia-sports":
      return <AllCheckViiiReiERainhaDaPraiaBeachWrestilingManausAmazonas2024 />;
    case "copa-mestre-acai-de-jiu-jitsu-2024-ginasio-arrudao-francisco-beltao-pr":
    case "xvii-copa-parana-de-jiu-jitsu-ginasio-de-esportes-alcides-pan-toledo-pr-gi":
    case "xvii-copa-parana-de-jiu-jitsu-ginasio-de-esportes-alcides-pan-toledo-pr-nogi":
      return <AllCheckCopaMestreAcaiDeJiuJitsu2024 />;
    case "xxxii-copa-orley-lobato-de-jiu-jitsu-cel-nininberg-guerra-manaus-am":
    case "ii-copa-arena-parintins-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-gi":
    case "ii-copa-arena-parintins-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-nogi":
    case "i-copa-thomasi-de-jiu-jitsu-parque-do-japao-maringa-pr":
    case "iv-opem-terra-santa-de-jiu-jistu-ginasio-braulio-nelson-da-conceicao-gi":
    case "iv-opem-terra-santa-de-jiu-jistu-ginasio-braulio-nelson-da-conceicao-nogi":
      return <AllCheckXxxiiCopaOrleyLobatoDeJiuJitsu />;
    case "i-orixicombat-open-jiu-jitsu-ginasio-municipal-de-oriximina-pa":
      return <AllCheckIOrixicombatOpenJiuJitsu />;
    case "copa-amizade-de-jiu-jitsu-2024-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr":
    case "copa-fronteira-de-jiu-jitsu-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr":
      return <AllCheckJiuJitsuLaranjaEVerdeJuntos />;
    case "copa-lobo-bravo-cresol-de-jiu-jitsu-ginasio-assuncao-de-nossa-senhora-guarapuava-pr":
      return <AllCheckCopaLoboBravoCresolDeJiuJitsu />;
    case "old-iv-opem-terra-santa-de-jiu-jistu-ginasio-braulio-nelson-da-conceicao-gi":
      return <AllCheckIvOpenTerraSantaDeJiuJitsu />;
    case "vii-open-amazonas-top-team-de-jiu-jitsu-manaus-am-gi":
      return <AllCheckViiOpenAmazonasTopTeamDeJiuJitsu />;
    case "copa-cianorte-de-jiu-jitsu-cianorte-pr-gi":
      return <AllCheckCategoriesCopaCianorteDeJiuJitsu />;
    case "vi-rei-da-praia-de-jiu-jitsu-santarem-pa-nogi":
      return <AllCheckViReiDaPraiaDeJiuJitsuNoGi />;
    case "ii-copa-champions-kids-de-jiu-jitsu-maringa-pr-gi":
      return <AllCheckIiCopaChampionsKidsDeJiuJitsu />;
    case "iii-open-rocha-e-moraes-de-jiu-jitsu-manaus-am-gi":
      return <AllCheckIiiOpenRochaEMoraesDeJiuJitsu />;
    case "i-open-laranjeiras-do-sul-de-jiu-jitsu-laranjeiras-pr-gi":
      return <AllCheckIOpenLaranjeirasDoSulDeJiuJitsu />;
    case "estadual-gf-team-amazonas-jiu-jitsu-manaus-am-gi":
    case "estadual-gf-team-amazonas-jiu-jitsu-manaus-am-nogi":
      return <AllCheckEstadualGfTeamAmazonasDeJiuJitsu />;
    case "xxix-copa-luis-neto-jiu-jitsu-manaus-am-gi":
    case "xxix-copa-luis-neto-jiu-jitsu-manaus-am-nogi":
    case "copa-manacapuru-de-jiu-jitsu-mananacapuru-am-gi":
    case "copa-manacapuru-de-jiu-jitsu-mananacapuru-am-nogi":
      return <AllCheckXxixCopaLuisNetoDeJiuJitsu />;
    case "etapa-oriximina-xxxii-copa-orley-lobato-jiu-jitsu-oriximina-pa-gi":
    case "etapa-oriximina-xxxii-copa-orley-lobato-jiu-jitsu-oriximina-pa-nogi":
      return <AllCheckEtapaOriximinaXxxiiCopaOrleyLobatoDeJiuJitsu />;
    case "campeonato-parintinense-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-gi":
    case "campeonato-parintinense-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-nogi":
      return <AllCheckCampeonatoParintinsenseDeJiuJitsu />;
    case "xviii-copa-parana-de-jiu-jitsu-cascavel-pr-gi":
    case "xviii-copa-parana-de-jiu-jitsu-cascavel-pr-nogi":
      return <AllCheckXviiiCopaParanaDeJiuJitsu />;
    default:
      return <h1>Checagem não liberada para esse torneio</h1>;
  }
}

export default AllCheck;
