import { useEffect, useState } from "react";
import NavBar from "../../components/organisms/navbar";
import {
  Container,
  Content,
  InfoContainerStatus,
  InfoContainerText,
  PageLocationTitle,
  PageTitle,
  PageTitleContainer,
  StatusButton,
  SubscriptionContainer,
  SubscriptionContent,
  SubscriptionDescription,
  SubscriptionItem,
  SubscriptionList,
  SubscriptionTitle,
} from "../profile/subscriptions/styles";
import api from "../../services/api";
import { toast } from "react-toastify";
import useAuthenticate from "../../hooks/useAuthenticate";
import Loading from "../../components/atoms/loading";
import React from "react";

import { useParams } from "react-router-dom";
import { StringToSlug, StringToSlugPraia } from "../../utils/SlugConverter";
import AllCheckCategoriesSeletivaManausOlimpica2024 from "./tournament/all-check-category-seletiva-manaus-olimpica-2024";
import AllCheckCategoriesCheckViiiReiERainhaDaPraiaBeachWrestiling2024 from "./tournament/all-check-category-viii-rei-e-rainha-da-praia-beach-wrestiling-manaus-amazonas-2024";
import AllCheckCategoriesCopaAmizadeDeJiuJitsu2024 from "./tournament/all-check-category-copa-amizade-de-jiu-jitsu-2024";
import AllCheckCategoriesCopaMestreAcaiDeJiuJitsu2024 from "./tournament/all-check-category-copa-mestre-acai-de-jiu-jitsu-2024";
import AllCheckCategoriesXxxiiCopaOrleyLobatoDeJiuJitsu from "./tournament/all-check-category-xxxii-copa-orley-lobato-de-jiu-jitsu";
import AllCheckCategoriesIOrixicombatOpenJiuJitsu from "./tournament/all-check-category-i-orixicombat-open-jiu-jitsu";
import AllCheckCategoriesJiuJitsuLaranjaEVerdeJuntos from "./tournament/all-check-category-jiu-jitsu-laranja-e-verde-juntos";
import AllCheckCategoriesCopaLoboBravoCresolDeJiuJitsu from "./tournament/all-check-category-copa-lobo-bravo-cresol-de-jiu-jitsu";
import AllCheckCategoriesIvOpenTerraSantaDeJiuJitsu from "./tournament/all-check-category-iv-open-terra-santa-de-jiu-jitsu";
import AllCheckCategoriesOpenAmazonasTopTeamDeJiuJitsu from "./tournament/all-check-category-vii-open-amazonas-top-team-de-jiu-jitsu";
import AllCheckCategoriesCopaCianorteDeJiuJitsu from "./tournament/all-check-category-copa-cianorte-de-jiu-jitsu";
import AllCheckCategoriesViReiDaPraiaDeJiuJitsuNoGi from "./tournament/all-check-category-vi-rei-da-praia-de-jiu-jitsu-no-gi";
import AllCheckCategoriesIiCopaChampionsKidsDeJiuJitsu from "./tournament/all-check-category-ii-copa-champions-kids-de-jiu-jitsu";
import AllCheckCategoriesIiiOpenRochaEMoraesDeJiuJitsu from "./tournament/all-check-category-iii-open-rocha-e-moraes-de-jiu-jitsu";
import AllCheckCategoriesIOpenLaranjeirasDoSulDeJiuJitsu from "./tournament/all-check-category-i-open-laranjeira-do-sul-de-jiu-jitsu";
import AllCheckCategoriesEstadualGfTeamAmazonasDeJiuJitsu from "./tournament/all-check-category-estadual-gf-team-amazonas-jiu-jitsu";
import AllCheckCategoriesXxixCopaLuisNetoDeJiuJitsu from "./tournament/all-check-category-xxix-copa-luis-neto-jiu-jitsu";
import AllCheckCategoriesEtapaOriximinaXxxiiCopaOrleyLobatoDeJiuJitsu from "./tournament/all-check-category-etapa-oriximina-xxxii-copa-orley-lobato-jiu-jitsu";
import AllCheckCategoriesCampeonatoParintinenseDeJiuJitsu from "./tournament/all-check-category-campeonato-parintinense-de-jiu-jitsu copy";
import AllCheckCategoriesXviiiCopaParanaDeJiuJitsu from "./tournament/all-check-category-xviii-copa-parana-de-jiu-jitsu";

const AllCheckCategories = () => {
  const { slug } = useParams();

  switch (slug) {
    case "seletiva-manaus-olimpica-2024-ginasio-ninimberg-guerra-sao-jorge-manaus-am":
    case "i-rei-e-rainha-da-praia-beach-boxing-manaus-amazonas-2023-manaus-amazonas":
      return <AllCheckCategoriesSeletivaManausOlimpica2024 />;
    case "viii-rei-e-rainha-da-praia-beach-wrestling-2024-nossa-praia-sports":
      return <AllCheckCategoriesCheckViiiReiERainhaDaPraiaBeachWrestiling2024 />;
      return <AllCheckCategoriesCopaAmizadeDeJiuJitsu2024 />;
    case "copa-mestre-acai-de-jiu-jitsu-2024-ginasio-arrudao-francisco-beltao-pr":
    case "xvii-copa-parana-de-jiu-jitsu-ginasio-de-esportes-alcides-pan-toledo-pr-gi":
    case "xvii-copa-parana-de-jiu-jitsu-ginasio-de-esportes-alcides-pan-toledo-pr-nogi":
      return <AllCheckCategoriesCopaMestreAcaiDeJiuJitsu2024 />;
    case "xxxii-copa-orley-lobato-de-jiu-jitsu-cel-nininberg-guerra-manaus-am":
    case "ii-copa-arena-parintins-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-gi":
    case "ii-copa-arena-parintins-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-nogi":
    case "i-copa-thomasi-de-jiu-jitsu-parque-do-japao-maringa-pr":
    case "iv-opem-terra-santa-de-jiu-jistu-ginasio-braulio-nelson-da-conceicao-gi":
    case "iv-opem-terra-santa-de-jiu-jistu-ginasio-braulio-nelson-da-conceicao-nogi":
      return <AllCheckCategoriesXxxiiCopaOrleyLobatoDeJiuJitsu />;
    case "i-orixicombat-open-jiu-jitsu-ginasio-municipal-de-oriximina-pa":
      return <AllCheckCategoriesIOrixicombatOpenJiuJitsu />;
    case "copa-amizade-de-jiu-jitsu-2024-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr":
    case "copa-fronteira-de-jiu-jitsu-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr":
      return <AllCheckCategoriesJiuJitsuLaranjaEVerdeJuntos />;
    case "copa-lobo-bravo-cresol-de-jiu-jitsu-ginasio-assuncao-de-nossa-senhora-guarapuava-pr":
      return <AllCheckCategoriesCopaLoboBravoCresolDeJiuJitsu />;
    case "OLD-iv-opem-terra-santa-de-jiu-jistu-ginasio-braulio-nelson-da-conceicao-gi":
      return <AllCheckCategoriesIvOpenTerraSantaDeJiuJitsu />;
    case "vii-open-amazonas-top-team-de-jiu-jitsu-manaus-am-gi":
      return <AllCheckCategoriesOpenAmazonasTopTeamDeJiuJitsu />;
    case "copa-cianorte-de-jiu-jitsu-cianorte-pr-gi":
      return <AllCheckCategoriesCopaCianorteDeJiuJitsu />;
    case "vi-rei-da-praia-de-jiu-jitsu-santarem-pa-nogi":
      return <AllCheckCategoriesViReiDaPraiaDeJiuJitsuNoGi />;
    case "ii-copa-champions-kids-de-jiu-jitsu-maringa-pr-gi":
      return <AllCheckCategoriesIiCopaChampionsKidsDeJiuJitsu />;
    case "iii-open-rocha-e-moraes-de-jiu-jitsu-manaus-am-gi":
      return <AllCheckCategoriesIiiOpenRochaEMoraesDeJiuJitsu />;
    case "i-open-laranjeiras-do-sul-de-jiu-jitsu-laranjeiras-pr-gi":
      return <AllCheckCategoriesIOpenLaranjeirasDoSulDeJiuJitsu />;
    case "estadual-gf-team-amazonas-jiu-jitsu-manaus-am-gi":
    case "estadual-gf-team-amazonas-jiu-jitsu-manaus-am-nogi":
      return <AllCheckCategoriesEstadualGfTeamAmazonasDeJiuJitsu />;
    case "xxix-copa-luis-neto-jiu-jitsu-manaus-am-gi":
    case "xxix-copa-luis-neto-jiu-jitsu-manaus-am-nogi":
    case "copa-manacapuru-de-jiu-jitsu-mananacapuru-am-gi":
    case "copa-manacapuru-de-jiu-jitsu-mananacapuru-am-nogi":
      return <AllCheckCategoriesXxixCopaLuisNetoDeJiuJitsu />;
    case "etapa-oriximina-xxxii-copa-orley-lobato-jiu-jitsu-oriximina-pa-gi":
    case "etapa-oriximina-xxxii-copa-orley-lobato-jiu-jitsu-oriximina-pa-nogi":
      return <AllCheckCategoriesEtapaOriximinaXxxiiCopaOrleyLobatoDeJiuJitsu />;
    case "campeonato-parintinense-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-gi":
    case "campeonato-parintinense-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-nogi":
      return <AllCheckCategoriesCampeonatoParintinenseDeJiuJitsu />;
    case "xviii-copa-parana-de-jiu-jitsu-cascavel-pr-gi":
    case "xviii-copa-parana-de-jiu-jitsu-cascavel-pr-nogi":
        return <AllCheckCategoriesXviiiCopaParanaDeJiuJitsu />;
    default:
      return <h1>Checagem para esse Torneio não encontrado!</h1>;
  }
};

export default AllCheckCategories;
